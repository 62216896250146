@import '@/styles/variables';
@import '@/styles/breakpoints';

// -----Start------ Card Component
.card {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  height: 100%;
  width: 100%;

  & > .cardBox {
    background-color: $black-900;
    display: flex;
    flex-direction: column;
    border: 1px solid $gray-shadow-20;
    max-width: 32rem;
    min-width: 3rem;
    padding: 1rem 1rem 3rem;
    border-radius: 8px;
    width: 100%;
    height: max-content;
    margin: 0;

    @include responsive('xs') {
      padding: 1rem 2rem 2rem;
      max-width: 34rem;
    }

    @include responsive('sm') {
      border-radius: 1rem;
      margin: 0;
      height: max-content;
      padding: 1rem 2rem 3rem;
    }

    @include responsive('md') {
      max-width: 35rem;
    }

    @include responsive('lg') {
      max-width: 40rem;
      padding: 3rem 3rem 4rem;
    }
  }
}

// -----Finish------ Card Component

// -----Start------ backToHome Component
.backToHome {
  display: none;
  margin: 0;

  @include h-responsive('md') {
    display: block;
    position: absolute;
    width: max-content;
  }

  @include responsive('sm') {
    margin: 0;
  }

  @include responsive('md') {
    margin: 1rem;
  }

  @include responsive('lg') {
    margin: 1rem 2rem;
  }

  button {
    font-family: 'Segoe UI', sans-serif;
    display: inline;
    padding: 2rem;
    background-color: transparent;

    &:hover {
      & > span,
      svg {
        color: $orange-400;
      }
    }

    svg {
      vertical-align: middle;
      width: auto;
      height: 0.75rem;
      position: relative;
      top: 1px;
      fill: currentColor;
      color: $white-100;
      transition: all 0.3s ease-in-out;

      @include responsive('sm') {
        height: 1rem;
      }
    }

    span {
      text-wrap: nowrap;
      vertical-align: middle;
      margin-left: 0.5rem;
      color: $white-100;
      font-size: 1rem;
      transition: all 0.3s ease-in-out;

      @include responsive('sm') {
        font-size: 1.2rem;
      }
    }
  }
}

// -----Finish------ backToHome Component
